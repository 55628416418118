import React, { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';

const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [click, setClick] = useState(true);

  const handleResetPassword = async () => {
    try {
      const response = await axios.post('https://quantri.cicpropfirm.com/wp-json/custom/v1/reset-password', { email });
      toast.success(response.data.message);
      setClick(false);
      // Thực hiện các hành động khác sau khi gửi yêu cầu reset password thành công
    } catch (error) {
      toast.error('Error sending reset password request');
    }
  };

  return (
    <div className="front">
      <div className="centered-front">
        <div style={{ margin: '25px 0px 0px', textAlign: 'center' }}>
          <h3 style={{ margin: '10px 0px 20px' }}>Reset password</h3>
          {
            click ? <>
                <p style={{ lineHeight: '22px' }}>Please type here your email address and press Send. We will email you a link to reset your password.</p>
                <form>
                    <div style={{ transform: 'translateX(-16px)', marginBottom: '30px' }}>
                    <input id="email" name="email" className="front-input" placeholder="Email address" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <input type="button" className="button-front-active" value="Send" onClick={handleResetPassword} style={{ transform: 'translateX(-7px)' }} />
                </form>
              </>:
              <>
                <p style={{ lineHeight: '22px' }}>We have emailed you a link to reset your password. Please check you email inbox.</p>
              </>
          }
          <br /><br />
          <Link to="/">
            <p style={{ color: 'white' }}>Back</p>
          </Link>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default ResetPassword;
