import React from 'react';
import Sidebar from '../../sidebar';

const View = () => {
  return <main>
  <div className="flex-grow"></div>
    <div className="centered d-flex">
     <Sidebar/>
      <div class="content-container">
        <div class="breadcrumb">Fidelcrest Trade &gt; <b>Contact support</b></div>
        <h3>Contact support</h3>
        <div class="content-box">
          <label for="topic">Topic</label>
          <select id="topic" name="topic">
              <option value="Review">Review</option>
              <option value="Payout">Payout</option>
              <option value="Refund">Refund</option>
              <option value="Technical_support">Technical support</option>
              <option value="Other">Other</option>
          </select>
          <label for="subject">Subject</label>
          <input id="subject" name="subject"/>
          <label for="message">Message</label>
          <textarea id="message" name="message"></textarea>
          <div class="button-container">
            <input type="submit" class="button-front-active" value="Send"/>
          </div>
          <div class="Toastify"></div>
        </div>
      </div>
    </div>
  <div className="flex-grow"></div>
</main>;
}
export default View;
