import React, { useState, useEffect } from "react";
import { Link, useLocation } from 'react-router-dom';
import Logo from '../assets/images/logo-bg.png';

function Header() {
  // Default state if there's no data in localStorage
  const [auth, setAuth] = useState({});
  const [menuMobile, setMenuMobile] = useState(false);
  const location = useLocation();
  
  useEffect(() => {
    const storedData = localStorage.getItem('auth');
    setAuth(JSON.parse(storedData));
  }, []);

  

  const handleExit = () => {
      // Thực hiện bất kỳ xử lý thoát nào ở đây (ví dụ: đóng cửa sổ trình duyệt).
      // Lưu ý: Trình duyệt có thể chặn một số cách tương tác trực tiếp với cửa sổ.
      localStorage.clear();
      window.close(); // Đóng cửa sổ trình duyệt (Lưu ý: đôi khi không hoạt động trên tất cả các trình duyệt)
  };

  return (
    <>
    <header>
      <div className="flex-grow"></div>
      <div className="centered">
        <table border="0" className="header-table">
          <tbody>
            <tr>
              <td className="logo-cell">
                <a href="https://cicpropfirm.com/" className="color-white">
                  <img alt="logo" src={Logo}/>
                </a>
              </td>
              <td className="custom-menu">
                {
                  menuMobile ? (
                    <span onClick={() => setMenuMobile(false)} className="span-mobile material-icons">close</span>
                  ) : (
                    <span onClick={() => setMenuMobile(true)} className="span-mobile material-icons">menu</span>
                  )
                }
              </td>
              <td className="user-cell">
                <div data-tip="Sign out">
                  <Link to="/" className="color-white">
                    <div className="user-info">
                      <div className="material-icons-outlined">person</div>
                      <div className="user-name">{auth?.user_display_name || 'Guest'}</div>
                    </div>
                  </Link>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="flex-grow"></div>

    </header>
    
    
    {
      menuMobile ? (
        <div className="custom-menu-bg">
          <div className="padding-0-20">
            
            <div className="menu main-menu-trade">
                <div className="main-menu-trade-1">
                  <table border="0">
                      <tbody>
                          <tr onClick={() => setMenuMobile(false)}>
                              <td><Link to="/dashboard"><span className="material-icons-outlined">dashboard</span></Link></td>
                              <td className={location.pathname === '/dashboard' ? 'font-700-text-left' : 'font-400-text-left'}>
                                  <Link to="/dashboard" >
                                      <div className="menu-width-180"><span>Dashboard</span></div>
                                  </Link>
                              </td>
                          </tr>
                          <tr onClick={() => setMenuMobile(false)}>
                              <td><Link to="/challenge"><span className="material-icons-outlined">verified_user</span></Link></td>
                              <td className={location.pathname === '/challenge' ? 'font-700-text-left' : 'font-400-text-left'}>
                              <Link to="/challenge">
                                  <div className="menu-width-180"><span>Challenge</span></div>
                              </Link>
                              </td>
                          </tr>
                          <tr onClick={() => setMenuMobile(false)}>
                              <td><Link to="/profile"><span className="material-icons-outlined">person_outline</span></Link></td>
                              <td className={location.pathname === '/profile' ? 'font-700-text-left' : 'font-400-text-left'}>
                              <Link to="/profile">
                                  <div className="menu-width-180"><span>Profile</span></div>
                              </Link>
                              </td>
                          </tr>
                          <tr onClick={() => setMenuMobile(false)}>
                              <td><Link to="/password"><span className="material-icons-outlined">password</span></Link></td>
                              <td className={location.pathname === '/password' ? 'font-700-text-left' : 'font-400-text-left'}>
                              <Link to="/password">
                                  <div className="menu-width-180"><span>Change password</span></div>
                              </Link>
                              </td>
                          </tr>
                          <tr onClick={() => setMenuMobile(false)}>
                              <td><a href="/"><span className="material-icons-outlined">logout</span></a></td>
                              <td className="text-left">
                              <a href="/" onClick={handleExit}>
                                  <div className="menu-width-180"><span>Sign out</span></div>
                              </a>
                              </td>
                          </tr>
                      </tbody>
                  </table>
                </div>
            </div>

          </div>
        </div>
      ) : (
        <></>
      )
    }
    
    </>
  );
}

export default Header;
