
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

import Header from './components/header';
import Footer from './components/footer';
import { Outlet } from "react-router-dom";

const App = ({setAuthToken}) => {
  return (<>
        <Header setAuthToken={setAuthToken}/>
        <Outlet setAuthToken={setAuthToken}/>
        <Footer setAuthToken={setAuthToken}/>
    </>
  )
};

export default App;