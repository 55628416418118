import { Link } from "react-router-dom";

import Logo from '../assets/images/logo-bg.png';
import ImgCC from '../assets/images/mc-visa.png';
import skrill from '../assets/images/skrill.png'
import neteller from '../assets/images/neteller.png';
import laurels from '../assets/images/laurel.png';

function Footer() {
    return <footer>
        <div className="flex-grow-1"></div>
        <div className="centered footer-color-white">           
            <div className="custom-footer new-class-end">
                <p>Fidelcrest is the trading name of Fidelcrest Ltd, Cyprus.</p>
                <p>Fidelcrest.com and trade.fidelcrest.com are owned and operated by Fidelcrest Ltd, registered office at Arch. Makariou III &amp; 1-7 Evagorou, MITSI 3, 1st floor, office 102 C, 1065 Nicosia, Cyprus (Company number HE413263)</p>
                <div className="risk-policy-link"><span>Risk Policy</span></div>
                <br/>
                <div className="rights-reserved">© 2018 - 2023 All Rights Reserved by Fidelcrest Group</div>
            </div> 
        </div>
        <div className="flex-grow-1"></div>
    </footer>;
}

export default Footer;