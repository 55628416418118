import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import QRCode from 'qrcode.react';
import Logo from '../../../assets/images/logo-bg.png';
import { ToastContainer, toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';

const Checkout = ({route,navigate}) => {
    const [address, setAddress] = useState('');
    const location = useLocation();
    const { state: value } = location;

    const handleCopy = (textToCopy) => {
        // Tạo một thẻ input để đặt giá trị cần sao chép
        const tempInput = document.createElement('input');
        tempInput.value = textToCopy;
    
        // Thêm thẻ input vào body để có thể chọn và sao chép nó
        document.body.appendChild(tempInput);
    
        // Chọn toàn bộ nội dung trong input
        tempInput.select();
    
        // Thực hiện lệnh sao chép
        document.execCommand('copy');
    
        // Xóa thẻ input không còn cần thiết
        document.body.removeChild(tempInput);
        return toast.info('Copied');
    };
    const generateOrderID = () => {
      // Tạo một order ID duy nhất, có thể sử dụng thời gian hoặc một thuật toán khác
      return Date.now().toString();
    };
  
    const handlePayment = async () => {
      try {
        // Thực hiện yêu cầu API để tạo yêu cầu thanh toán
        const response = await axios.get(
          'https://quantri.cicpropfirm.com/wp-json/binance/v1/deposit-address', { } );
  
        setAddress(response.data.deposit_address.address);
      } catch (error) {
        console.error('Error creating payment request:', error);
      }
    };
  
    useEffect(() => {
        handlePayment();
    }, []);
  
    return (
    <div className="front">
        <div className="flex-grow"></div>
        <div className="centered-front" style={{maxWidth: '400px'}}>
            <div className="logo-container">
            <a href="/">
                <div className="logo-content">
                <img className="logo1" alt="logo" src={Logo} />
                </div>
            </a>
            </div>
            <div style={{margin: '25px 0px 10px', textAlign: 'center'}}>
            <h3 style={{margin: '10px 0px 20px'}}>Pay with USDT</h3>
            {/* <div style={{padding: '20px 0px', background: 'white'}}>
                {
                    address && <QRCode size={200} value={address} />
                }
            </div> */}
            <div style={{padding: '20px', whiteSpace: 'normal', overflowWrap: 'break-word', boxSizing: 'border-box'}}>
                <div style={{display: 'inline-block'}}>
                    <table>
                        <tbody>
                            <tr>
                                <td colSpan={2}>Price <span style={{fontWeight: '700', fontSize: '17px'}}>EUR {value.total}</span></td>
                            </tr>
                            <tr>
                                <td>Address</td>
                                <td style={{padding: '0px 5px'}}>
                                    <span data-tip="Copy amount to clipboard" onClick={() => handleCopy(address)} data-offset="{top: '-0', left: '-8'}" currentitem="false" style={{cursor: 'pointer', display: 'flex', cursor: 'pointer', justifyContent: 'center', alignItems: 'center'}}>
                                        <div className="__react_component_tooltip td936d81a-3688-4e9d-8b00-20a3f4878a2a place-bottom type-dark" id="td936d81a-3688-4e9d-8b00-20a3f4878a2a" data-id="tooltip">
                                            {address}
                                        </div>
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td>Order code</td>
                                <td style={{padding: '0px 5px'}}>
                                    <span data-tip="Copy amount to clipboard" data-offset="{top: '-0', left: '-8'}" currentitem="false" style={{cursor: 'pointer', display: 'flex', cursor: 'pointer', justifyContent: 'center', alignItems: 'center'}}>
                                        <div className="__react_component_tooltip td936d81a-3688-4e9d-8b00-20a3f4878a2a place-bottom type-dark" id="td936d81a-3688-4e9d-8b00-20a3f4878a2a" data-id="tooltip">
                                            {value.code}
                                        </div>
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div style={{padding: '0px 10px 10px', whiteSpace: 'normal', overflowWrap:'break-word', boxSizing: 'border-box'}}>Please be advised that your cryptocurrency operator might charge you a commission for this transaction.</div>
            <div style={{transform: 'translateX(-5px)', padding: '0px', boxSizing: 'border-box'}}>
                <a href="/dashboard">
                    <p style={{paddingTop: '15px', color: 'white', transform: 'translateX(5px)'}}>Back</p>
                </a>
            </div>
        </div>
    </div>
    <ToastContainer/>
    <div className="flex-grow"></div>
</div>
    );
}

export default Checkout;
