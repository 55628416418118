import React, { useState } from 'react';
import Sidebar from '../../sidebar';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordAgain, setNewPasswordAgain] = useState('');

  const handleChangePassword = async () => {
    try {
      // Kiểm tra xem mật khẩu mới và mật khẩu xác nhận có khớp nhau không
      if (newPassword !== newPasswordAgain) {
        toast.error('New passwords do not match');
        return;
      }

      // Lấy token từ lưu trữ (đảm bảo bạn đã lưu token khi người dùng đăng nhập)
      const authToken = localStorage.getItem('jwtToken'); // Hoặc sử dụng sessionStorage

      // Gửi yêu cầu đổi mật khẩu đến server với token
      const response = await axios.post(
        'https://quantri.cicpropfirm.com/wp-json/api/v1/change-password',
        {
          oldPassword,
          newPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      toast.success(response.data.message);

      // Thực hiện các hành động khác sau khi đổi mật khẩu thành công
    } catch (error) {
      toast.error('Error changing password');
    }
  };

  return (
    <main>
      <div className="flex-grow"></div>
      <div className="centered d-flex">
        <Sidebar />
        <div className="content-container">
          <div className="breadcrumb">Trade &gt; <b>Change Password</b></div>
          <h3>Change Password</h3>
          <div className="content-box">
            <label htmlFor="oldPassword">Old password</label>
            <input id="oldPassword" name="oldPassword" type="password" value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} />
            <label htmlFor="newPassword">New password</label>
            <input id="newPassword" name="newPassword" type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
            <label htmlFor="newPasswordAgain">New password again</label>
            <input id="newPasswordAgain" name="newPasswordAgain" type="password" value={newPasswordAgain} onChange={(e) => setNewPasswordAgain(e.target.value)} />
            <div style={{ textAlign: 'center', margin: '10px', fontWeight: '500' }}>
              <input type="button" className="button-front-active" value="Save" style={{ display: 'inline-block', transform: 'translateX(-8px)', marginTop: '30px' }} onClick={handleChangePassword} />
            </div>
          </div>
        </div>
      </div>
      <div className="flex-grow"></div>
      <ToastContainer />
    </main>
  );
};

export default ChangePassword;
