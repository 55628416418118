import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';

import App from './App';
import HomeView from './components/vn/dashboard/View';
import ProfileView from './components/vn/profile/View';
import SupportView from './components/vn/support/View';
import PasswordView from './components/vn/password/View';
import ChallengeView from './components/vn/challenge/View';
import AuthLogin from './components/vn/auth/Login';
import AuthSignUp from './components/vn/auth/SignUp';
import PaymentView from './components/vn/checkout/View';
import AuthResetPassword from './components/vn/auth/ResetPassword';
import NotFound from './components/vn/404/View';

const Root = () => {
  const [authToken, setAuthToken] = useState(localStorage.getItem('jwtToken'));

  useEffect(() => {
  }, []);

  return (
    <Router>
      <Routes>
        {authToken && (
          <>
            <Route path="/" element={<App />}>

              {/* Home and Dashboard views */}
              <Route index element={<HomeView />} />
              <Route path="/dashboard" element={<HomeView />} />
              <Route path="/profile" element={<ProfileView />} />
              <Route path="/support" element={<SupportView />} />
              <Route path="/password" element={<PasswordView />} />
              <Route path="/challenge" element={<ChallengeView />} />

              {/* Not Found View */}
              <Route path="*" element={<HomeView />} />
            </Route>
            <Route path="/">
              <Route path="/checkout" element={<PaymentView />} />
            </Route>
          </>
        )}

        {!authToken && (
          <Route path="/">
                <Route path="/log-in" element={<AuthLogin setAuthToken={setAuthToken} />} />
                <Route path="/" element={<AuthLogin setAuthToken={setAuthToken} />} />
                <Route path="/reset-password" element={<AuthResetPassword />} />
                <Route path="/sign-up" element={<AuthSignUp />} />
                <Route path="*" element={<Navigate to="/log-in" />} />
          </Route>
        )}
      </Routes>
    </Router>
  );
};

const rootElement = document.getElementById('root');
ReactDOM.createRoot(rootElement).render(<Root />);

reportWebVitals();
