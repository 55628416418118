import React, {useEffect, useState} from 'react';
import Sidebar from '../../sidebar';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import BIC from '../../../assets/images/usdt.svg';
import { useNavigate } from 'react-router-dom';


const View = () => {
    const [customData, setCustomData] = useState(null);
    const [chosse, setChosse] = useState({'name': 0, 'capital': 0, 'mode': 0});
    const [loading, setLoading] = useState(true);
    const [total, setTotal] = useState(0);
    const [agree, setAgree] = useState({});
    const [add_ons, setAddOns] = useState({});
    const [value, setValue] = useState({'price' : 0, 'mode': 0, 'add_ons': {}});
    const [error, setError] = useState(null);
    const [category, setCategory] = useState([]);
    const [userData, setUserData] = useState({
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      dob: '',
      city: '',
      legal_address: '',
      zip_code: '',
    });  
    const navigate = useNavigate();

    const formatCurrency = (amount) => {
        // Kiểm tra xem amount có phải là một số không
        if (typeof amount !== 'number') {
          return 'Invalid input'; // hoặc bạn có thể throw một exception
        }
      
        // Sử dụng hàm toLocaleString để định dạng số và tiền tệ
        const formattedAmount = amount.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2, // Số lượng chữ số thập phân tối thiểu
        });
      
        return formattedAmount;
    }


    useEffect(() => {
        if (customData?.programs) {
          const price =
            customData?.programs[chosse.name]?.capital[chosse.capital].price || 0;
      
          const mode =
            customData?.programs[chosse.name].capital[chosse.capital].mode[chosse.mode].value ||
            0;

          const totalPrice = parseFloat(price) + parseFloat(mode);
      
          // Note: I've changed 'exchange' from const to let
          let exchange = customData?.exchange || parseFloat(0.891);
          const calculatedPrice = Math.floor(parseFloat(totalPrice) * parseFloat(exchange));
      
          let addon = 0;
          addon = Object.values(add_ons).reduce((total, v) => {
            return total + (parseFloat(calculatedPrice) * (parseFloat(v.price) / 100));
          }, 0);
      
          setTotal({'total': calculatedPrice+addon, 'mode' : mode, 'price' : price, 'add': add_ons });
        }
    }, [chosse, customData, add_ons]);
      
    

    useEffect(() => {
        // Gọi API để lấy thông tin người dùng đã cập nhật
        const fetchUpdatedUserInfo = async () => {
        try {
            // Lấy token từ localStorage (đảm bảo bạn đã lưu token sau khi đăng nhập)
            const authToken = localStorage.getItem('jwtToken');
            if (!authToken) {
            console.error('Authentication token not found');
            return;
            }

            // Gửi yêu cầu API để lấy thông tin người dùng
            const response = await axios.get('https://quantri.cicpropfirm.com/wp-json/api/v1/get-updated-user', {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });

            // Set thông tin người dùng vào state
            setUserData(response.data);
        } catch (error) {
            console.error('Error fetching updated user information:', error);
        } finally {
            // Đánh dấu là đã load xong
            setLoading(false);
        }
        };

        // Gọi hàm để lấy thông tin người dùng
        fetchUpdatedUserInfo();
    }, []); // Empty dependency array ensures the effect runs only once after mount
    
    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.get('https://quantri.cicpropfirm.com/wp-json/custom/v1/get-posts-by-category/19');
            // Assuming the API response is an object
            setCategory(response.data);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
    
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
        try {
            // Gửi yêu cầu GET đến API
            const response = await axios.get('https://quantri.cicpropfirm.com/wp-json/custom/v1/get-custom-data?id=251');
            
            // Lấy dữ liệu từ phản hồi
            setCustomData(response.data);

            // Kết thúc quá trình tải
            setLoading(false);
        } catch (error) {
            // Xử lý lỗi
            setError(error);
            setLoading(false);
        }
        };

        // Gọi hàm fetchData
        fetchData();
    }, []);

    const isAnyCheckboxUnchecked = () => {
        // Kiểm tra nếu có bất kỳ checkbox nào chưa được chọn (giá trị là false)
        return Object.values(agree).some(value => !value);
    };
    
    const handleCheckout = async () => {
        handleSave();

        console.log(!isAnyCheckboxUnchecked() && Object.values(agree).length === category.length);
        console.log(agree);
        
        if (!isAnyCheckboxUnchecked() && Object.values(agree).length === category.length) {
            const authToken = localStorage.getItem('jwtToken');
            const response = await axios.post('https://quantri.cicpropfirm.com/wp-json/custom/v1/save-data', total, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authToken}`,
                },
            });

            if(response.data.success) {
                navigate('/checkout', {state: response.data});
                return toast.success(response.data.message);
            } 
        } else {
            return toast.error('You need to read and agree to our policies.');
        }
    }

    const handleSave = async () => {
        try {
        // Kiểm tra xem có token trong localStorage không
        const authToken = localStorage.getItem('jwtToken');
        if (!authToken) {
            toast.error('Authentication token not found');
            return;
        }

        // Tạo dữ liệu yêu cầu từ state hiện tại
        const requestData = {
            first_name: userData.first_name,
            last_name: userData.last_name,
            email: userData.email,
            phone: userData.phone,
            dob: userData.dob,
            city: userData.city,
            legal_address: userData.legal_address,
            zip_code: userData.zip_code,
        };

        // Gửi yêu cầu API với token
        const response = await axios.post('https://quantri.cicpropfirm.com/wp-json/api/v1/update-user', requestData, {
            headers: {
            Authorization: `Bearer ${authToken}`,
            },
        });

            // Xử lý kết quả từ server (nếu cần)
        } catch (error) {
            toast.error('Error updating user information');
        }
    };


  return <main>
  <div className="flex-grow"></div>
    <div className="centered d-flex">
     <Sidebar/>
      <div className="content-container">
          Fidelcrest Trade &gt; <b>Challenge</b><br/><br/>
          <h3 className="phase-heading">Start Challenge</h3>
          <div className="content-box">
                <h3>Trading Program</h3>
                {
                    customData?.programs && customData?.programs.map((v, k) => <div key={k} onClick={() => setChosse({'name': k, 'capital': 0, 'mode': 0})} className="select button-selected" style={{fontSize: '14px'}}>{v.name && v.name}</div>)
                }
                
                <h3>Initial capital</h3>
                {
                    customData?.programs[chosse.name].capital &&
                    customData?.programs[chosse.name].capital.map((v, k) => (
                        <div
                        key={k}
                        onClick={() => {
                            setChosse({ name: chosse.name, capital: k, mode: 0 });
                        }}
                        style={{ fontSize: '14px' }}
                        className={`select ${chosse.capital === k ? 'button-selected' : 'button-passive'}`}
                        >
                        $ {v.price}
                        </div>
                    ))
                }
                {customData?.programs[chosse.name].capital[chosse.capital].mode &&  <h3>Risk mode</h3>}
                {
                    customData?.programs[chosse.name].capital[chosse.capital].mode && 
                    customData?.programs[chosse.name].capital[chosse.capital].mode.map((v, k) => 
                        <div key={k} 
                        onClick={() => {
                            setChosse({ name: chosse.name, capital: chosse.capital, mode: k });
                        }}
                        className={`select ${chosse.mode === k ? 'button-selected' : 'button-passive'}`} 
                        style={{fontSize: '14px'}}>{v.name}</div>
                    )
                }
                <h3>Add-ons</h3>
                
                    {
                        customData?.programs[chosse.name].add_ons && customData?.programs[chosse.name].add_ons.map((v, k) =>
                        <div style={{display: 'flex', padding: '2px 0px'}}  onChange={() => setAddOns(
                            {
                                ...add_ons,
                                [v.key]: add_ons[v.key]?.status == true ? {price : 0, 'status': false} : {price : v.value, 'status': true} 
                            }
                        )}>
                            <div>
                                <input type="checkbox" id={v.key} name={v.key} value={v.key} style={{cursor: 'pointer', fontSize: '40px', display: 'inline', width: '20px', textAlign: 'left'}}/>
                            </div>
                            <div>
                                <label htmlFor={v.key} style={{cursor: 'pointer', display: 'inline', color: 'rgb(0, 0, 0)', textAlign: 'left', fontWeight: '500'}}>{v.label}</label>
                            </div>
                            <div className="material-icons-outlined highlight-on-hover" style={{padding: '0px 10px', fontSize: '24px', transform: 'translateY(-3px)', cursor: 'pointer'}}>help_outline</div>
                        </div>)
                    }


                    <div style={{textAlign: 'center', transform: 'translateX(-7px)', margin: '70px 0px 0px', fontWeight: 800}}>Total</div>
                    <div className="price" style={{position: 'relative', textAlign: 'center', transform: 'translateX(-7px)', margin: '0px', color: 'rgb(255, 170, 6)', fontSize: '36px', fontWeight: '700'}}>
                        <div>
                            <div style={{display: 'inline-block', position: 'relative'}}><span>EUR {total?.total}</span></div>
                        </div>
                    </div>



                    <div style={{textAlign: 'center', transform: 'translateX(-7px)', margin: '0px 0px 30px', fontWeight: '500'}}>Including VAT</div>
                </div>
                <h3 className="phase-heading">Billing info</h3>
                <div className="content-box" style={{paddingBottom: '10px', minHeight: '60px'}}>
                    <div></div>
                    <label htmlFor="first_name">First name</label>
                    <input id="first_name" name="first_name" type="text"   value={userData.first_name} onChange={(e) => setUserData({...userData, 'first_name': e.target.value})} style={{opacity:'0.5', cursor: 'not-allowed'}}/>
                    <label htmlFor="last_name">Last name</label>
                    <input id="last_name" name="last_name" type="text"   value={userData.last_name} onChange={(e) => setUserData({...userData, 'last_name': e.target.value})} style={{opacity:'0.5', cursor: 'not-allowed'}}/>
                    <label htmlFor="email">Email address</label>
                    <input id="email" name="email" type="email"  value={userData.email} onChange={(e) => setUserData({...userData, 'email': e.target.value})} style={{opacity:'0.5', cursor: 'not-allowed'}}/>
                    <label htmlFor="phone">Phone number</label>
                    <input id="phone" name="phone" type="tel"   value={userData.phone} onChange={(e) => setUserData({...userData, 'phone': e.target.value})} style={{opacity:'0.5', cursor: 'not-allowed'}}/>
                    <label htmlFor="dob">Date of Birth</label>
                    <input id="dob" name="dob" type="date" value={userData.dob} onChange={(e) => setUserData({...userData, 'dob': e.target.value})} style={{opacity: 1, cursor: 'text'}}/>
                    <label htmlFor="city">City</label>
                    <input id="city" name="city" type="text" value={userData.city} onChange={(e) => setUserData({...userData, 'city': e.target.value})} style={{opacity: 1, cursor: 'text'}}/>
                    <label htmlFor="legal_address">Address</label>
                    <input id="legal_address" name="legal_address" type="text" value={userData.legal_address} onChange={(e) => setUserData({...userData, 'legal_address': e.target.value})} style={{opacity: 1, cursor: 'text'}}/>
                    <label htmlFor="zip_code">Zip / Postal Code</label>
                    <input id="zip_code" name="zip_code" type="text" value={userData.zip_code} onChange={(e) => setUserData({...userData, 'zip_code': e.target.value})} style={{opacity: 1, cursor: 'text'}}/>
                </div>
                <h3 className="phase-heading">Order and pay</h3>
                <div className="content-box" style={{paddingBottom: '10px', minHeight: '60px'}}>
                    <div></div>

                    <div style={{textAlign: 'center', transform: 'translateX(-7px)', margin: '70px 0px 0px', fontWeight: '800'}}>Total</div>
                    <div className="price" style={{position: 'relative', textAlign: 'center', transform: 'translateX(-7px)', margin: '0px', color: 'rgb(255, 170, 6)', fontSize: '36px', fontWeight: '700'}}>
                        <div>
                            <div style={{display: 'inline-block', position: 'relative'}}>EUR {total?.total}</div>
                        </div>
                    </div>


                    <div style={{textAlign: 'center', transform: 'translateX(-7px)', margin: '0px 0px 30px', fontWeight: 500}}>Including VAT</div>
                    <div style={{textAlign: 'center', verticalAlign: 'top', transform: 'translateX(4px)'}}>
                        <table style={{display: 'inline-block', transform: 'translateX(-10px)', maxWidth: '360px', textAlign: 'left'}}>
                            <tbody>
                            

                            {
                                category.map((v, k) => 
                                    <tr key={k}>
                                        <td style={{verticalAlign: 'top'}}>
                                            <input 
                                                type="checkbox" 
                                                id={v.id} 
                                                name={v.id} 
                                                value={v.id} 
                                                style={{cursor: 'pointer', fontSize: '40px', display: 'inline', width: '20px', textAlign: 'left'}} 
                                                checked={agree['agree_'+k]}  
                                                onChange={() => {
                                                    setAgree({...agree, ['agree_'+k]: !agree['agree_'+k]});
                                                    console.log(agree);
                                                }}
                                            />
                                        </td>
                                        <td style={{verticalAlign: 'top'}}>
                                            <label 
                                                htmlFor={v.id} 
                                                style={{cursor: 'pointer', display: 'inline', width: '200px', textAlign: 'left', fontWeight: '500'}} 
                                                onChange={() => {
                                                    setAgree({...agree, ['agree_'+k]: !agree['agree_'+k]});
                                                    console.log(agree);
                                                }}
                                            >
                                                I agree to <b>
                                                    <a rel="noreferrer" href={'https://cic.chuyenweb.com/news/'+v.id} style={{borderBottom: '2px solid rgb(255, 170, 6)'}}>{v.title}</a>
                                                </b>
                                            </label>
                                        </td>
                                    </tr>
                                )
                            }

                                

                            </tbody>
                        </table>
                    </div>
                    
                    <div style={{textAlign: 'center', margin: '10px 0px', transform: 'translateX(-8px)', color: 'red', fontWeight: '700'}}></div>
                    <h3 style={{textAlign: 'center', fontSize: '16px', opacity: 0.5, transform: 'translateX(-8px)', marginTop: '30px'}}>Choose Your Payment Method</h3>
                    <div style={{textAlign: 'center', margin: '20px 0px 0px'}}>

                        <div onClick={() => handleCheckout()} style={{display: 'inline-block', position: 'relative', margin: '10px', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 8px', border: '3px solid white', width: '200px', height: '180px', borderRadius: '10px', cursor: 'pointer'}}>
                            <img alt="main" src={BIC} style={{position: 'absolute', top: '40%', left: '50%', padding: '20px', background:'rgb(255, 170, 6)', borderRadius: '50%', transform: 'translate(-50%, -50%)', height: '100px'}}/>
                            <div style={{position: 'absolute', opacity: '0.5', bottom: '15px', left: '50%', transform: 'translateX(-50%)', width: '140px', fontWeight: '600'}}>USDT</div>
                        </div>

                    </div>


                </div>
            </div>
      <ToastContainer />
    </div>
  <div className="flex-grow"></div>
</main>;
}
export default View;
