import React, {useEffect, useState} from 'react';
import Sidebar from '../../sidebar';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

const View = () => {
  const [userData, setUserData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    dob: '',
    city: '',
    legal_address: '',
    zip_code: '',
  });  
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Gọi API để lấy thông tin người dùng đã cập nhật
    const fetchUpdatedUserInfo = async () => {
      try {
        // Lấy token từ localStorage (đảm bảo bạn đã lưu token sau khi đăng nhập)
        const authToken = localStorage.getItem('jwtToken');
        if (!authToken) {
          console.error('Authentication token not found');
          return;
        }

        // Gửi yêu cầu API để lấy thông tin người dùng
        const response = await axios.get('https://quantri.cicpropfirm.com/wp-json/api/v1/get-updated-user', {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });

        // Set thông tin người dùng vào state
        setUserData(response.data);
      } catch (error) {
        console.error('Error fetching updated user information:', error);
      } finally {
        // Đánh dấu là đã load xong
        setLoading(false);
      }
    };

    // Gọi hàm để lấy thông tin người dùng
    fetchUpdatedUserInfo();
  }, []); // Empty dependency array ensures the effect runs only once after mount


  const handleSave = async () => {
    try {
      // Kiểm tra xem có token trong localStorage không
      const authToken = localStorage.getItem('jwtToken');
      if (!authToken) {
        toast.error('Authentication token not found');
        return;
      }

      // Tạo dữ liệu yêu cầu từ state hiện tại
      const requestData = {
        first_name: userData.first_name,
        last_name: userData.last_name,
        email: userData.email,
        phone: userData.phone,
        dob: userData.dob,
        city: userData.city,
        legal_address: userData.legal_address,
        zip_code: userData.zip_code,
      };

      // Gửi yêu cầu API với token
      const response = await axios.post('https://quantri.cicpropfirm.com/wp-json/api/v1/update-user', requestData, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      // Xử lý kết quả từ server (nếu cần)
      toast.success(response.data.message || 'User information updated successfully');
    } catch (error) {
      toast.error('Error updating user information');
    }
  };

  return <main>
  <div className="flex-grow"></div>
    <div className="centered d-flex">
     <Sidebar/>
      <div class="content-container">
        <div class="profile-header">
            Fidelcrest Trade &gt; <b>Profile</b><br/><br/>
            <h3>Profile</h3>
        </div>
        <div class="content-box">

          <label for="first_name">First name</label>
          <input id="first_name" name="first_name" type="text"   value={userData.first_name} onChange={(e) => setUserData({...userData, 'first_name': e.target.value})} style={{opacity:'0.5', cursor: 'not-allowed'}}/>
          <label for="last_name">Last name</label>
          <input id="last_name" name="last_name" type="text"   value={userData.last_name} onChange={(e) => setUserData({...userData, 'last_name': e.target.value})} style={{opacity:'0.5', cursor: 'not-allowed'}}/>
          <label for="email">Email address</label>
          <input id="email" name="email" type="email"  value={userData.email} onChange={(e) => setUserData({...userData, 'email': e.target.value})} style={{opacity:'0.5', cursor: 'not-allowed'}}/>
          <label for="phone">Phone number</label>
          <input id="phone" name="phone" type="tel"   value={userData.phone} onChange={(e) => setUserData({...userData, 'phone': e.target.value})} style={{opacity:'0.5', cursor: 'not-allowed'}}/>
          <label for="dob">Date of Birth</label>
          <input id="dob" name="dob" type="date" value={userData.dob} onChange={(e) => setUserData({...userData, 'dob': e.target.value})} style={{opacity: 1, cursor: 'text'}}/>
          <label for="city">City</label>
          <input id="city" name="city" type="text" value={userData.city} onChange={(e) => setUserData({...userData, 'city': e.target.value})} style={{opacity: 1, cursor: 'text'}}/>
          <label for="legal_address">Address</label>
          <input id="legal_address" name="legal_address" type="text" value={userData.legal_address} onChange={(e) => setUserData({...userData, 'legal_address': e.target.value})} style={{opacity: 1, cursor: 'text'}}/>
          <label for="zip_code">Zip / Postal Code</label>
          <input id="zip_code" name="zip_code" type="text" value={userData.zip_code} onChange={(e) => setUserData({...userData, 'zip_code': e.target.value})} style={{opacity: 1, cursor: 'text'}}/>
          <div style={{textAlign: 'center', margin: '10px', fontWeight: 500}}>
              <input type="submit" class="button-front-active" onClick={handleSave} value="Save" style={{display: 'inline-block', transform: 'translateX(-8px)', marginTop: '30px'}}/>
          </div>
          <ToastContainer/>

        </div>
      </div>
    </div>
  <div className="flex-grow"></div>
</main>;
}
export default View;
