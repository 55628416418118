import React, {useEffect, useState} from 'react';
import axios from 'axios';
import Logo from '../../../assets/images/logo-bg.png';
import { ToastContainer, toast } from 'react-toastify';
import Select from 'react-select';
// import ReCAPTCHA from "react-google-recaptcha";

const Signup = () => {
  const [value, setValue] = useState({policy: false, receive: false, confirm: false});
  const handleAction = async () => {
    if(value.policy == false || value.receive == false || value.confirm == false) {
      toast.warning('You have not agreed to our policies.');
      return;
    }

    if(value.password_confirm !=  value.password || !value.password_confirm || !value.password) {
      toast.warning('Invalid password.');
      return;
    }

    try {
      const response = await axios.post('https://quantri.cicpropfirm.com/wp-json/api/v1/register', value);

      if (response.data && response.data.success) {
        toast.success('Account created. Check your email for login details.');
      } else if (response.data && response.data.error) {
        toast.error(response.data.error);
      } else {
        // Xử lý trường hợp không rõ lỗi hoặc trạng thái khác
        toast.error('An unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      console.error('Error:', error);

      // Hiển thị thông báo lỗi từ axios nếu có
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An error occurred. Please try again later.');
      }
    }
  };

  const options = [
    { value: 'mr', label: 'Mr.' },
    { value: 'mrs', label: 'Mrs.' },
    { value: 'ms', label: 'Ms.' },
    { value: 'mx', label: 'Mx.' },
  ];

  const countryOptions = [
    { value: 'AF', label: 'Afghanistan' },
    { value: 'AL', label: 'Albania' },
    { value: 'DZ', label: 'Algeria' },
    { value: 'AD', label: 'Andorra' },
    { value: 'AO', label: 'Angola' },
    { value: 'AG', label: 'Antigua and Barbuda' },
    { value: 'AR', label: 'Argentina' },
    { value: 'AM', label: 'Armenia' },
    { value: 'AU', label: 'Australia' },
    { value: 'AT', label: 'Austria' },
    { value: 'AZ', label: 'Azerbaijan' },
    { value: 'BS', label: 'Bahamas' },
    { value: 'BH', label: 'Bahrain' },
    { value: 'BD', label: 'Bangladesh' },
    { value: 'BB', label: 'Barbados' },
    { value: 'BY', label: 'Belarus' },
    { value: 'BE', label: 'Belgium' },
    { value: 'BZ', label: 'Belize' },
    { value: 'BJ', label: 'Benin' },
    { value: 'BT', label: 'Bhutan' },
    { value: 'BO', label: 'Bolivia' },
    { value: 'BA', label: 'Bosnia and Herzegovina' },
    { value: 'BW', label: 'Botswana' },
    { value: 'BR', label: 'Brazil' },
    { value: 'BN', label: 'Brunei' },
    { value: 'BG', label: 'Bulgaria' },
    { value: 'BF', label: 'Burkina Faso' },
    { value: 'BI', label: 'Burundi' },
    { value: 'KH', label: 'Cambodia' },
    { value: 'CM', label: 'Cameroon' },
    { value: 'CA', label: 'Canada' },
    { value: 'CV', label: 'Cape Verde' },
    { value: 'CF', label: 'Central African Republic' },
    { value: 'TD', label: 'Chad' },
    { value: 'CL', label: 'Chile' },
    { value: 'CN', label: 'China' },
    { value: 'CO', label: 'Colombia' },
    { value: 'KM', label: 'Comoros' },
    { value: 'CG', label: 'Congo' },
    { value: 'CR', label: 'Costa Rica' },
    { value: 'HR', label: 'Croatia' },
    { value: 'CU', label: 'Cuba' },
    { value: 'CY', label: 'Cyprus' },
    { value: 'CZ', label: 'Czech Republic' },
    { value: 'DK', label: 'Denmark' },
    { value: 'DJ', label: 'Djibouti' },
    { value: 'DM', label: 'Dominica' },
    { value: 'DO', label: 'Dominican Republic' },
    { value: 'EC', label: 'Ecuador' },
    { value: 'EG', label: 'Egypt' },
    { value: 'SV', label: 'El Salvador' },
    { value: 'GQ', label: 'Equatorial Guinea' },
    { value: 'ER', label: 'Eritrea' },
    { value: 'EE', label: 'Estonia' },
    { value: 'ET', label: 'Ethiopia' },
    { value: 'FJ', label: 'Fiji' },
    { value: 'FI', label: 'Finland' },
    { value: 'FR', label: 'France' },
    { value: 'GA', label: 'Gabon' },
    { value: 'GM', label: 'Gambia' },
    { value: 'GE', label: 'Georgia' },
    { value: 'DE', label: 'Germany' },
    { value: 'GH', label: 'Ghana' },
    { value: 'GR', label: 'Greece' },
    { value: 'GD', label: 'Grenada' },
    { value: 'GT', label: 'Guatemala' },
    { value: 'GN', label: 'Guinea' },
    { value: 'GW', label: 'Guinea-Bissau' },
    { value: 'GY', label: 'Guyana' },
    { value: 'HT', label: 'Haiti' },
    { value: 'HN', label: 'Honduras' },
    { value: 'HU', label: 'Hungary' },
    { value: 'IS', label: 'Iceland' },
    { value: 'IN', label: 'India' },
    { value: 'ID', label: 'Indonesia' },
    { value: 'IR', label: 'Iran' },
    { value: 'IQ', label: 'Iraq' },
    { value: 'IE', label: 'Ireland' },
    { value: 'IL', label: 'Israel' },
    { value: 'IT', label: 'Italy' },
    { value: 'JM', label: 'Jamaica' },
    { value: 'JP', label: 'Japan' },
    { value: 'JO', label: 'Jordan' },
    { value: 'KZ', label: 'Kazakhstan' },
    { value: 'KE', label: 'Kenya' },
    { value: 'KI', label: 'Kiribati' },
    { value: 'KP', label: 'North Korea' },
    { value: 'KR', label: 'South Korea' },
    { value: 'KW', label: 'Kuwait' },
    { value: 'KG', label: 'Kyrgyzstan' },
    { value: 'LA', label: 'Laos' },
    { value: 'LV', label: 'Latvia' },
    { value: 'LB', label: 'Lebanon' },
    { value: 'LS', label: 'Lesotho' },
    { value: 'LR', label: 'Liberia' },
    { value: 'LY', label: 'Libya' },
    { value: 'LI', label: 'Liechtenstein' },
    { value: 'LT', label: 'Lithuania' },
    { value: 'LU', label: 'Luxembourg' },
    { value: 'MK', label: 'North Macedonia' },
    { value: 'MG', label: 'Madagascar' },
    { value: 'MW', label: 'Malawi' },
    { value: 'MY', label: 'Malaysia' },
    { value: 'MV', label: 'Maldives' },
    { value: 'ML', label: 'Mali' },
    { value: 'MT', label: 'Malta' },
    { value: 'MH', label: 'Marshall Islands' },
    { value: 'MR', label: 'Mauritania' },
    { value: 'MU', label: 'Mauritius' },
    { value: 'MX', label: 'Mexico' },
    { value: 'FM', label: 'Micronesia' },
    { value: 'MD', label: 'Moldova' },
    { value: 'MC', label: 'Monaco' },
    { value: 'MN', label: 'Mongolia' },
    { value: 'ME', label: 'Montenegro' },
    { value: 'MA', label: 'Morocco' },
    { value: 'MZ', label: 'Mozambique' },
    { value: 'MM', label: 'Myanmar' },
    { value: 'NA', label: 'Namibia' },
    { value: 'NR', label: 'Nauru' },
    { value: 'NP', label: 'Nepal' },
    { value: 'NL', label: 'Netherlands' },
    { value: 'NZ', label: 'New Zealand' },
    { value: 'NI', label: 'Nicaragua' },
    { value: 'NE', label: 'Niger' },
    { value: 'NG', label: 'Nigeria' },
    { value: 'NO', label: 'Norway' },
    { value: 'OM', label: 'Oman' },
    { value: 'PK', label: 'Pakistan' },
    { value: 'PW', label: 'Palau' },
    { value: 'PS', label: 'Palestine' },
    { value: 'PA', label: 'Panama' },
    { value: 'PG', label: 'Papua New Guinea' },
    { value: 'PY', label: 'Paraguay' },
    { value: 'PE', label: 'Peru' },
    { value: 'PH', label: 'Philippines' },
    { value: 'PL', label: 'Poland' },
    { value: 'PT', label: 'Portugal' },
    { value: 'QA', label: 'Qatar' },
    { value: 'RO', label: 'Romania' },
    { value: 'RU', label: 'Russia' },
    { value: 'RW', label: 'Rwanda' },
    { value: 'KN', label: 'Saint Kitts and Nevis' },
    { value: 'LC', label: 'Saint Lucia' },
    { value: 'VC', label: 'Saint Vincent and the Grenadines' },
    { value: 'WS', label: 'Samoa' },
    { value: 'SM', label: 'San Marino' },
    { value: 'ST', label: 'Sao Tome and Principe' },
    { value: 'SA', label: 'Saudi Arabia' },
    { value: 'SN', label: 'Senegal' },
    { value: 'RS', label: 'Serbia' },
    { value: 'SC', label: 'Seychelles' },
    { value: 'SL', label: 'Sierra Leone' },
    { value: 'SG', label: 'Singapore' },
    { value: 'SK', label: 'Slovakia' },
    { value: 'SI', label: 'Slovenia' },
    { value: 'SB', label: 'Solomon Islands' },
    { value: 'SO', label: 'Somalia' },
    { value: 'ZA', label: 'South Africa' },
    { value: 'SS', label: 'South Sudan' },
    { value: 'ES', label: 'Spain' },
    { value: 'LK', label: 'Sri Lanka' },
    { value: 'SD', label: 'Sudan' },
    { value: 'SR', label: 'Suriname' },
    { value: 'SZ', label: 'Eswatini' },
    { value: 'SE', label: 'Sweden' },
    { value: 'CH', label: 'Switzerland' },
    { value: 'SY', label: 'Syria' },
    { value: 'TW', label: 'Taiwan' },
    { value: 'TJ', label: 'Tajikistan' },
    { value: 'TZ', label: 'Tanzania' },
    { value: 'TH', label: 'Thailand' },
    { value: 'TL', label: 'Timor-Leste' },
    { value: 'TG', label: 'Togo' },
    { value: 'TO', label: 'Tonga' },
    { value: 'TT', label: 'Trinidad and Tobago' },
    { value: 'TN', label: 'Tunisia' },
    { value: 'TR', label: 'Turkey' },
    { value: 'TM', label: 'Turkmenistan' },
    { value: 'TV', label: 'Tuvalu' },
    { value: 'UG', label: 'Uganda' },
    { value: 'UA', label: 'Ukraine' },
    { value: 'AE', label: 'United Arab Emirates' },
    { value: 'GB', label: 'United Kingdom' },
    { value: 'US', label: 'United States' },
    { value: 'UY', label: 'Uruguay' },
    { value: 'UZ', label: 'Uzbekistan' },
    { value: 'VU', label: 'Vanuatu' },
    { value: 'VA', label: 'Vatican City' },
    { value: 'VE', label: 'Venezuela' },
    { value: 'VN', label: 'Vietnam' },
    { value: 'YE', label: 'Yemen' },
    { value: 'ZM', label: 'Zambia' },
    { value: 'ZW', label: 'Zimbabwe' },
  ];

  return (
    <div className="front">
      <div className="flex-grow"></div>
        <div className="centered-front">
          <a href="/" className="logo-link">
            <div className="logo-container">
              <table border="0" className="logo-table">
                <tbody>
                  <tr>
                    <td className="logo-cell">
                      <img className="logo1" alt="logo" src={Logo} />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </a>
          <div className="signup-container">
            <div className="signup-content">
              <h3 className="signup-heading">Sign up</h3>
              <p className="signup-subheading">Enter your details to create your account</p>
              <div className="signup-inputs">
                <Select
                  defaultValue={value.gender}
                  onChange={(e) => setValue({...value, 'gender': e.value})}
                  options={options}
                  styles={{textAlign: 'left'}}
                  className='front-input front-select'
                  placeholder="Title"
                />
                <input id="first_name" name="first_name" className="front-input" value={value.first_name} onChange={(e) => setValue({...value, 'first_name': e.target.value})} placeholder="First name" />
                <input id="last_name" name="last_name" className="front-input" value={value.last_name} onChange={(e) => setValue({...value, 'last_name': e.target.value})} placeholder="Last name" />
                <input id="email" name="email" type="email" className="front-input" value={value.email} onChange={(e) => setValue({...value, 'email': e.target.value})} placeholder="Email address" />
                <input id="phone" name="phone" type="tel" className="front-input" value={value.phone} onChange={(e) => setValue({...value, 'phone': e.target.value})} placeholder="Phone number" />
                <input id="password" name="password" type="password" className="front-input" value={value.password} onChange={(e) => setValue({...value, 'password': e.target.value})} placeholder="Password" />
                <input id="password_confirm" name="password_confirm" type="password" className="front-input" value={value.password_confirm} onChange={(e) => setValue({...value, 'password_confirm': e.target.value})} placeholder="Password confirm" />
                <Select
                  defaultValue={value.country}
                  onChange={(e) => setValue({...value, 'country': e.value})}
                  options={countryOptions}
                  styles={{textAlign: 'left'}}
                  className='front-input front-select'
                  placeholder="Country of residence"
                />
                {/* <ReCAPTCHA
                  sitekey="6Lc5L0ApAAAAAKQalJfryp7Gbl_IXKjkR9WAkhCA"  // Điền khóa Recaptcha của bạn vào đây
                  setValue={(e) => setValue({...value, 'recaptcha': e})}
                  className='front-input front-select'
                /> */}
                <table style={{color: 'rgb(255, 255, 255)', width: '100%', fontWeight: '400', position: 'relative', lineHeight: '18px', textAlign: 'left', padding: '20px 0px 0px 10px', transform: 'translateX(15px)'}}>
                  <tbody>
                      <tr style={{cursor: 'pointer'}}> 
                        <td style={{paddingRight: '10px', color: '#fff'}}>
                          <span onClick={() => setValue({...value, 'policy': !value.policy})}  style={{color: '#fff'}} class="material-icons-outlined">
                            {!value.policy? 'check_box_outline_blank' : 'check_box'}
                          </span>
                        </td>
                        <td>I agree to the processing of my personal data according to our&nbsp;<a href="https://cic.chuyenweb.com/news/3" target="_blank" style={{color: 'white', paddingBottom: '2px', borderBottom: '3px solid rgba(255, 255, 255, 0.533)'}}>privacy policy</a>.</td>
                      </tr>
                      <tr style={{cursor: 'pointer'}}>
                        <td style={{paddingRight: '10px', color: '#fff'}}>
                          <span onClick={() => setValue({...value, 'receive': !value.receive})}  style={{color: '#fff'}} class="material-icons-outlined">
                          {!value.receive? 'check_box_outline_blank' : 'check_box'}
                          </span>
                        </td>
                        <td>I want to receive our newsletter.</td>
                      </tr>
                      <tr style={{cursor: 'pointer'}}>
                        <td style={{paddingRight: '10px'}}>
                          <span onClick={() => setValue({...value, 'confirm': !value.confirm})} style={{color: '#fff'}} class="material-icons-outlined">
                            {!value.confirm? 'check_box_outline_blank' : 'check_box'}
                          </span>
                          </td>
                        <td>I confirm that my name is correct and matches my government issued ID.</td>
                      </tr>
                  </tbody>
                </table>
                <input type="button" className="button-front-active no-webkit" value="Sign up" onClick={handleAction} />
              </div>
            </div>
          </div>
          <br/>
          <p className="terms-condition">By signing up, you confirm that you agree to our <b><a target="_blank" rel="noreferrer" href="https://fidelcrest.com/general-terms-and-conditions" className="terms-link">General Terms And Conditions</a></b></p>
          <div className="back-link">
            <a href="/">
              <p className="back-text">Back</p>
            </a>
          </div>
        </div>
        <ToastContainer/>
      <div className="flex-grow"></div>
    </div>
  );
}

export default Signup;
