import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Sidebar from '../../sidebar';
import BIC from '../../../assets/images/usdt.svg';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate, createSearchParams } from 'react-router-dom';

const View = () => {
    const [click, setClick] = useState({'paid': '','success': '','unpaid': ''})
    const [userData, setUserData] = useState({
      paid: [],
      unpaid: [],
      success: [],
    });
    const navigate = useNavigate();
  
    useEffect(() => {
      fetchData();
    }, []);
    
    const fetchData = async () => {
        try {
            const authToken = localStorage.getItem('jwtToken');
            const response = await axios.get('https://quantri.cicpropfirm.com/wp-json/api/v1/user-data', {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });

            setUserData(response.data);
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
    };
    
    const handleUpdateStatus = async (idToUpdate) => {
        const shouldUpdate = window.confirm('Are you sure you want to update the status?');
    
        if (shouldUpdate) {
          try {
            const response = await axios.post(`https://quantri.cicpropfirm.com/wp-json/api/v1/update-status/?id=${idToUpdate}`);
            fetchData();
            
          return toast.success(response.data.message);
          } catch (error) {
            console.error('Error updating status:', error);
            // Handle error if needed
          }
        }
      };
  
    const handleCheckout = async (value) => {
        navigate('/checkout', { state: value });
    };
  return <main>
  <div className="flex-grow"></div>
    <div className="centered d-flex">
     <Sidebar/>
      <div class="content-container">
        <div class="dashboard-container">
          Fidelcrest Trade &gt; <b>Dashboard</b><br/><br/>
          <h3 class="phase-heading">Phase 1 (challenge)</h3>
          <div class="content-box" style={{paddingBottom: '10px', minHeight: '60px'}}>
              <div>
                  <div class="status-badge">Awaiting payment</div>
              </div>
              <table class="data-table" style={{width: '100%'}}>
                  <tbody>
                        {userData.unpaid && userData.unpaid.map((item, key) => (
                            <>
                            <tr class="data-row" style={{cursor: 'pointer'}} onClick={() => setClick({...click, unpaid: key === click.unpaid ? '': key })}>
                                <td>
                                    <div class="dt-block">
                                        <span class="material-icons-outlined">auto_graph</span>
                                    </div>
                                </td>
                                <td class="data-info">
                                    <b>{item.code}</b><br/>{item.created_at}
                                </td>
                                <td class="data-icontext-right"> 
                                    { click.unpaid === key ? 'Collapse': 'Expand'}
                                    <span class="material-icons">expand_more</span>
                                </td>
                            </tr>

                            {
                                click.unpaid === key &&  <tr style={{borderBottom: '1px solid rgb(200, 200, 200)'}}>
                                    <td colspan="4">
                                        <table border="0" style={{borderCollapse: 'collapse', width: '100%', margin: '10px 0px'}}>
                                            <tbody>
                                                <tr>
                                                <td colspan="3" style={{textAlign: 'left', paddingTop: '0px', width: '100%'}}>
                                                    <div style={{fontSize: '15px', fontWeight: '700', padding: '10px 0px'}}>Account {item.code}</div>
                                                </td>
                                                </tr>
                                                <tr>
                                                <td style={{userSelect: 'text', fontWeight: '500', whiteSpace: 'nowrap'}}>Capital</td>
                                                <td style={{userSelect: 'text', paddingLeft: '20px', fontWeight: '700', width: '100%', wordBreak: 'break-word'}}>$ {item.price}</td>
                                                <td style={{width: '90%'}}></td>
                                                </tr>
                                                <tr>
                                                <td style={{userSelect: 'text', fontWeight: '500', whiteSpace: 'nowrap'}}>Price</td>
                                                <td style={{userSelect: 'text', paddingLeft: '20px', fontWeight: '700', width: '100%', wordBreak: 'break-word'}}>{item.total} EUR</td>
                                                <td style={{width: '90%'}}></td>
                                                </tr>
                                                <tr>
                                                <td colspan="3" style={{textAlign: 'center', width: '100%'}}>
                                                    <h3 style={{textAlign: 'center', fontSize: '16px', opacity: '0.5', transform: 'translateX(-8px)', marginTop: '30px'}}>Choose Your Payment Method</h3>
                                                    <div style={{textAlign: 'center', margin: '20px 0px 0px'}}>
                                                        <div onClick={() => handleCheckout(item)} style={{display: 'inline-block', position: 'relative', margin: '10px', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 8px', border: '3px solid white', width: '200px', height: '180px', borderRadius: '10px', cursor: 'pointer'}}>
                                                            <img alt="main" src={BIC} style={{position: 'absolute', top: '40%', left: '50%', padding: '20px', background: 'rgb(255, 170, 6)', borderRadius: '50%', transform: 'translate(-50%, -50%)', height: '100px'}}/>
                                                            <div style={{position: 'absolute', opacity: '0.5', bottom: '15px', left: '50%', transform: 'translateX(-50%)', width: '140px', fontWeight: '600'}}>USDT</div>
                                                        </div>
                                                    </div>
                                                </td>
                                                </tr>
                                                <tr>
                                                    <td colspan="3" style={{textAlign: 'center', width: '100%'}}>
                                                        <button onClick={() => handleUpdateStatus(item.id)} style={{opacity: 1, minWidth: '100px', userSelect: 'none', margin: '10px 5px 0px 0px', cursor: 'pointer'}}>Cancel Order</button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                <td colspan="3" style={{textAlign: 'center', width: '100%'}}>Pressing this cancels the order and cannot be undone.</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            }
                            </>
                        ))}
                  </tbody>
              </table>
          </div>
          <h3 class="phase-heading">Phase 2 (verification)</h3>
          <div class="content-box" style={{paddingBottom: '10px', minHeight: '60px'}}>
              <div>
                  <div class="status-badge">Paid payment</div>
                </div>
              <table class="data-table">
                    <tbody>
                        {userData.paid && userData.paid.map((item, key) => (
                        <>
                        <tr class="data-row" style={{cursor: 'pointer'}} onClick={() => setClick({...click, paid: key === click.paid ? '': key })}>
                            <td>
                                <div class="dt-block">
                                    <span class="material-icons-outlined">auto_graph</span>
                                </div>
                            </td>
                            <td class="data-info">
                                <b>{item.code}</b><br/>{item.created_at}
                            </td>
                            <td class="data-icontext-right"> 
                                { click.paid === key ? 'Collapse': 'Expand'}
                                <span class="material-icons">expand_more</span>
                            </td>
                        </tr>

                        {
                            click.paid === key &&  <tr style={{borderBottom: '1px solid rgb(200, 200, 200)'}}>
                                <td colspan="4">
                                    <table border="0" style={{borderCollapse: 'collapse', width: '100%', margin: '10px 0px'}}>
                                        <tbody>
                                            <tr>
                                            <td colspan="3" style={{textAlign: 'left', paddingTop: '0px', width: '100%'}}>
                                                <div style={{fontSize: '15px', fontWeight: '700', padding: '10px 0px'}}>Account {item.code}</div>
                                            </td>
                                            </tr>
                                            <tr>
                                            <td style={{userSelect: 'text', fontWeight: '500', whiteSpace: 'nowrap'}}>Capital</td>
                                            <td style={{userSelect: 'text', paddingLeft: '20px', fontWeight: '700', width: '100%', wordBreak: 'break-word'}}>$ {item.price}</td>
                                            <td style={{width: '90%'}}></td>
                                            </tr>
                                            <tr>
                                            <td style={{userSelect: 'text', fontWeight: '500', whiteSpace: 'nowrap'}}>Price</td>
                                            <td style={{userSelect: 'text', paddingLeft: '20px', fontWeight: '700', width: '100%', wordBreak: 'break-word'}}>{item.total} EUR</td>
                                            <td style={{width: '90%'}}></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        }
                        </>))}
                    </tbody>
              </table>
          </div>
          <h3 class="phase-heading">Phase 3 (professional)</h3>
          <div class="content-box" style={{paddingBottom: '10px', minHeight: '60px'}}>
              <div>
                  <div class="status-badge">Trade completed</div>
                </div>
              <table class="data-table">
                    <tbody>
                        {userData.success && userData.success.map((item, key) => (
                        <>
                        <tr class="data-row" style={{cursor: 'pointer'}} onClick={() => setClick({...click, success: key === click.success ? '': key })}>
                            <td>
                                <div class="dt-block">
                                    <span class="material-icons-outlined">auto_graph</span>
                                </div>
                            </td>
                            <td class="data-info">
                                <b>{item.code}</b><br/>{item.created_at}
                            </td>
                            <td class="data-icontext-right"> 
                                { click.success === key ? 'Collapse': 'Expand'}
                                <span class="material-icons">expand_more</span>
                            </td>
                        </tr>

                        {
                            click.success === key &&  <tr style={{borderBottom: '1px solid rgb(200, 200, 200)'}}>
                                <td colspan="4">
                                    <table border="0" style={{borderCollapse: 'collapse', width: '100%', margin: '10px 0px'}}>
                                        <tbody>
                                            <tr>
                                            <td colspan="3" style={{textAlign: 'left', paddingTop: '0px', width: '100%'}}>
                                                <div style={{fontSize: '15px', fontWeight: '700', padding: '10px 0px'}}>Account {item.code}</div>
                                            </td>
                                            </tr>
                                            <tr>
                                            <td style={{userSelect: 'text', fontWeight: '500', whiteSpace: 'nowrap'}}>Capital</td>
                                            <td style={{userSelect: 'text', paddingLeft: '20px', fontWeight: '700', width: '100%', wordBreak: 'break-word'}}>$ {item.price}</td>
                                            <td style={{width: '90%'}}></td>
                                            </tr>
                                            <tr>
                                            <td style={{userSelect: 'text', fontWeight: '500', whiteSpace: 'nowrap'}}>Price</td>
                                            <td style={{userSelect: 'text', paddingLeft: '20px', fontWeight: '700', width: '100%', wordBreak: 'break-word'}}>{item.total} EUR</td>
                                            <td style={{width: '90%'}}></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        }
                        </>))}
                    </tbody>
              </table>
          </div>
      </div>
      </div>
    </div>
  <div className="flex-grow"></div>
</main>;
}
export default View;
