import React, { useState } from 'react';
import axios from 'axios';
import Logo from '../../../assets/images/logo-bg.png';
import { ToastContainer, toast } from 'react-toastify';

const Login = ({setAuthToken}) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = async () => {
    try {
      const response = await axios.post('https://quantri.cicpropfirm.com/wp-json/jwt-auth/v1/token', {
        username,
        password,
      });
  
      const token = response.data.token;
      setAuthToken(token);
      localStorage.setItem('jwtToken', token);
  
      const responseData = response.data;
      const jsonData = JSON.stringify(responseData);
      localStorage.setItem('auth', jsonData);
  
      // Thực hiện các hành động khác sau khi đăng nhập thành công
    } catch (error) {
      toast.error('Account or password does not exist');
    }
  };
  
  
  return (
    <div className="front">
      <div className="flex-grow"></div>
      <div className="centered-front">
        <div className="logo-container">
          <a href="/">
            <div className="logo-content">
              <img className="logo1" alt="logo" src={Logo} />
            </div>
          </a>
        </div>
        <div className="login-section">
          <h3>Login</h3>
          <form>
            <div className="input-container">
              <input id="userid" name="userid" className="front-input" placeholder="Email address" value={username} onChange={(e) => setUsername(e.target.value)} />
              <input id="password" name="password" type="password" className="front-input" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
            </div>
            <a href="/reset-password" className="forgot-password">
              <p>Forgot password</p>
            </a>
            <input type="button" className="button-front-active no-webkit" value="Log in" onClick={handleLogin} />
          </form>
          <div className="login-info">
            <p><a target="_blank" rel="noreferrer" href="https://my.challenge.fidelcrest.com/index.php?p=login">Login here</a><span>&nbsp;&nbsp;to the old trader area to<br />manage my old Fidelcrest accounts</span></p>
            <br />
            <a href="/">
              <p>Back</p>
            </a>
          </div>
        </div>
      </div>
      <ToastContainer/>
      <div className="flex-grow"></div>
    </div>
  );
}

export default Login;
